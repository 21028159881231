import {
  Array,
  Boolean,
  Dictionary,
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Tuple,
  Union,
} from "runtypes";

import { TimezoneName } from "../dateTypes";
import {
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
} from "../display-table/columnDisplayFormatTypes";
import { ExploreCustomTimeUnit } from "../explore/exploreCustomTimeUnit.js";
import { getNormalEnum } from "../runtypeEnums";
import { NoCodeCellDataframe } from "../sql/dataSourceTableConfig.js";

export const ChartTypeSelectorOptionLiteral = Union(
  Literal("column_grouped"),
  Literal("column_stacked"),
  Literal("column_stacked100"),
  Literal("bar_grouped"),
  Literal("bar_stacked"),
  Literal("bar_stacked100"),
  Literal("histogram"),
  Literal("line"),
  Literal("scatter"),
  Literal("area_stacked"),
  Literal("area_stacked100"),
  Literal("pie"),
);
export type ChartTypeSelectorOption = Static<
  typeof ChartTypeSelectorOptionLiteral
>;
export const ChartTypeSelectorOption = getNormalEnum(
  ChartTypeSelectorOptionLiteral,
);

export const ChartColorHex = String;
export type ChartColorHex = Static<typeof ChartColorHex>;

export const ChartSortByValue = Union(
  Literal("y"),
  Literal("-y"),
  Literal("x"),
  Literal("-x"),
);
export type ChartSortByValue = Static<typeof ChartSortByValue>;

export const ChartSortDirectionLiteral = Union(
  Literal("ascending"),
  Literal("descending"),
);
export const ChartSortDirection = getNormalEnum(ChartSortDirectionLiteral);
export type ChartSortDirection = Static<typeof ChartSortDirectionLiteral>;

// NB: we need to flatten the union to use `getNormalEnum` below
export const ChartSortLiteral = Union(
  ...ChartSortByValue.alternatives,
  ...ChartSortDirectionLiteral.alternatives,
);
export const ChartSort = getNormalEnum(ChartSortLiteral);
export type ChartSort = Static<typeof ChartSortLiteral>;

export const ChartCustomSort = Array(String);
export type ChartCustomSort = Static<typeof ChartCustomSort>;

export const ChartSortOrCustomSort = Union(ChartSortLiteral, ChartCustomSort);
export type ChartSortOrCustomSort = Static<typeof ChartSortOrCustomSort>;

export const ChartTimeUnitLiteral = Union(
  Literal("year"),
  Literal("quarter"),
  Literal("month"),
  Literal("week"),
  Literal("day"),
  Literal("dayofyear"),
  Literal("date"),
  Literal("hours"),
  Literal("minutes"),
  Literal("seconds"),
  Literal("milliseconds"),
  Literal("yearquarter"),
  Literal("yearquartermonth"),
  Literal("yearmonth"),
  Literal("yearmonthdate"),
  Literal("yearmonthdatehours"),
  Literal("yearmonthdatehoursminutes"),
  Literal("yearmonthdatehoursminutesseconds"),
  Literal("yearweek"),
  Literal("yearweekday"),
  Literal("yearweekdayhours"),
  Literal("yearweekdayhoursminutes"),
  Literal("yearweekdayhoursminutesseconds"),
  Literal("yeardayofyear"),
  Literal("quartermonth"),
  Literal("monthdate"),
  Literal("monthdatehours"),
  Literal("monthdatehoursminutes"),
  Literal("monthdatehoursminutesseconds"),
  Literal("weekday"),
  Literal("weeksdayhours"),
  Literal("weekdayhoursminutes"),
  Literal("weekdayhoursminutesseconds"),
  Literal("dayhours"),
  Literal("dayhoursminutes"),
  Literal("dayhoursminutesseconds"),
  Literal("hoursminutes"),
  Literal("hoursminutesseconds"),
  Literal("minutesseconds"),
  Literal("secondsmilliseconds"),
);
export type ChartStandardTimeUnit = Static<typeof ChartTimeUnitLiteral>;
export const ChartStandardTimeUnit = getNormalEnum(ChartTimeUnitLiteral);

export const ChartTimeUnit = Union(ChartTimeUnitLiteral, ExploreCustomTimeUnit);
export type ChartTimeUnit = Static<typeof ChartTimeUnit>;

/**
 * Use this when you have a timeUnit object that can contain a custom time unit,
 * and you only care about the default time units (e.g. passing them into vega)
 */
export const maybeGetStandardChartTimeUnit = (
  timeUnit: ChartTimeUnit | undefined,
): ChartStandardTimeUnit | undefined => {
  if (ExploreCustomTimeUnit.guard(timeUnit)) {
    return undefined;
  }
  return timeUnit;
};
export const ChartAggregateLiteral = Union(
  Literal("count"),
  Literal("sum"),
  Literal("average"),
  Literal("distinct"),
  Literal("median"),
  Literal("max"),
  Literal("min"),
  Literal("ci0"),
  Literal("ci1"),
  Literal("mean"),
  Literal("missing"),
  Literal("product"),
  Literal("q1"),
  Literal("q3"),
  Literal("stderr"),
  Literal("stdev"),
  Literal("stdevp"),
  Literal("valid"),
  Literal("values"),
  Literal("variance"),
  Literal("variancep"),
);
export type ChartAggregate = Static<typeof ChartAggregateLiteral>;
export const ChartAggregate = getNormalEnum(ChartAggregateLiteral);

export const ChartAxisLabels = Record({
  angle: Optional(Number),
});
export type ChartAxisLabels = Static<typeof ChartAxisLabels>;

export const ChartAxisTicks = Record({
  size: Optional(Number),
  count: Optional(Number),
  minStep: Optional(Number),
});
export type ChartAxisTicks = Static<typeof ChartAxisTicks>;

export const ChartGridStyle = Union(
  Literal("solid"),
  Literal("dash"),
  Literal("none"),
);
export type ChartGridStyle = Static<typeof ChartGridStyle>;

export const ChartAxisGrid = Record({
  style: ChartGridStyle,
  width: Optional(Number),
});
export type ChartAxisGrid = Static<typeof ChartAxisGrid>;

export const ChartNumberAxisScale = Union(
  Literal("linear"),
  Literal("pow"),
  Literal("sqrt"),
  Literal("log"),
);
export type ChartNumberAxisScale = Static<typeof ChartNumberAxisScale>;

export const ChartAxisStyle = Record({
  position: Optional(
    Union(Literal("top"), Literal("bottom"), Literal("left"), Literal("right")),
  ),
  labels: Optional(ChartAxisLabels),
  grid: Optional(ChartAxisGrid),
  ticks: Optional(ChartAxisTicks),
  min: Optional(Number),
  max: Optional(Number),
  zero: Optional(Boolean),
});
export type ChartAxisStyle = Static<typeof ChartAxisStyle>;

export const ChartAxisReferenceLineLabelPosition = Union(
  Literal("outside"),
  Literal("top-left"),
  Literal("top-right"),
  Literal("bottom-left"),
  Literal("bottom-right"),
);
export type ChartAxisReferenceLineLabelPosition = Static<
  typeof ChartAxisReferenceLineLabelPosition
>;

const ChartDataTypeString = Literal("string");
const ChartDataTypeNumber = Literal("number");
const ChartDataTypeDatetime = Literal("datetime");
export const ChartDataTypeLiteral = Union(
  ChartDataTypeString,
  ChartDataTypeNumber,
  ChartDataTypeDatetime,
);
export type ChartDataType = Static<typeof ChartDataTypeLiteral>;
export const ChartDataType = getNormalEnum(ChartDataTypeLiteral);

export const ChartAxisReferenceLineDataType = Union(
  ChartDataTypeNumber,
  ChartDataTypeDatetime,
  ChartDataTypeString,
);
export type ChartAxisReferenceLineDataType = Static<
  typeof ChartAxisReferenceLineDataType
>;

export const ChartLineStroke = Union(
  Literal("solid"),
  Literal("dashed"),
  Literal("dotted"),
);
export type ChartLineStroke = Static<typeof ChartLineStroke>;

export const ChartAxisReferenceLine = Record({
  id: String,
  label: Optional(String),
  labelPosition: Optional(ChartAxisReferenceLineLabelPosition),
  labelAngle: Optional(Number),
  strokeColor: Optional(ChartColorHex),
  strokeWidth: Optional(Number),
  strokeStyle: Optional(ChartLineStroke),
  value: Optional(Union(Number, String)),
  dataType: ChartAxisReferenceLineDataType,
});
export type ChartAxisReferenceLine = Static<typeof ChartAxisReferenceLine>;

export const BaseChartAxis = Record({
  type: ChartDataTypeLiteral,
  title: Optional(String),
  aggregate: Optional(ChartAggregateLiteral),
  style: ChartAxisStyle,
  numberFormat: Optional(NumberColumnDisplayFormat),
  scale: Optional(ChartNumberAxisScale),
  sort: Optional(ChartSortLiteral),
  timeUnit: Optional(ChartTimeUnit),
  referenceLines: Optional(Array(ChartAxisReferenceLine)),
});

export const ChartStringAxis = BaseChartAxis.extend({
  type: ChartDataTypeString,
});
export type ChartStringAxis = Static<typeof ChartStringAxis>;

export const ChartDatetimeAxis = BaseChartAxis.extend({
  type: ChartDataTypeDatetime,
  datetimeFormat: Optional(DatetimeColumnDisplayFormat),
});
export type ChartDatetimeAxis = Static<typeof ChartDatetimeAxis>;

export const ChartNumberAxis = BaseChartAxis.extend({
  type: ChartDataTypeNumber,
});
export type ChartNumberAxis = Static<typeof ChartNumberAxis>;

export const ChartAxis = Union(
  ChartStringAxis,
  ChartNumberAxis,
  ChartDatetimeAxis,
);
export type ChartAxis = Static<typeof ChartAxis>;

const ChartAxisDataFrame = {
  dataFrameColumn: Optional(String),
};
export const ChartStringAxisWithData = ChartStringAxis.omit("sort").extend({
  ...ChartAxisDataFrame,
  // support custom sort for categorical x-axis via an array of x-axis values
  sort: Optional(ChartSortOrCustomSort),
});
export const ChartNumberAxisWithData =
  ChartNumberAxis.extend(ChartAxisDataFrame);
export const ChartDatetimeAxisWithData =
  ChartDatetimeAxis.extend(ChartAxisDataFrame);
export const ChartAxisWithData = Union(
  ChartStringAxisWithData,
  ChartNumberAxisWithData,
  ChartDatetimeAxisWithData,
);
export type ChartAxisWithData = Static<typeof ChartAxisWithData>;

export const ChartTooltipField = Record({
  dataFrameColumn: String,
  name: Optional(String),
  type: Optional(ChartDataTypeLiteral),
  aggregate: Optional(ChartAggregateLiteral),
});
export type ChartTooltipField = Static<typeof ChartTooltipField>;

export const ChartAutoTooltip = Record({
  type: Literal("auto"),
});
export type ChartAutoTooltip = Static<typeof ChartAutoTooltip>;

// legacy Chart Cell semantics for manual tooltip
// we infer all sorts of stuff around aggregate/format/timeunit
export const ChartManualTooltip = Record({
  type: Literal("manual"),
  includeAuto: Optional(Boolean),
  fields: Array(ChartTooltipField),
});
export type ChartManualTooltip = Static<typeof ChartManualTooltip>;

// new Explore Cell semantics for custom tooltips
// everything is up to the user
export const ChartCustomTooltip = Record({
  type: Literal("custom"),
  includeAuto: Boolean,
  fields: Array(ChartTooltipField),
});
export type ChartCustomTooltip = Static<typeof ChartCustomTooltip>;

export const ChartTooltip = Union(
  ChartAutoTooltip,
  ChartManualTooltip,
  ChartCustomTooltip,
);
export type ChartTooltip = Static<typeof ChartTooltip>;

export const ChartColorScheme = Union(
  Literal("greys"),
  Literal("blues"),
  Literal("reds"),
  Literal("greens"),
  Literal("viridis"),
  Literal("inferno"),
  Literal("plasma"),
  Literal("cividis"),
  Literal("spectral"),
  Literal("redblue"),
  Literal("purplegreen"),
);
export type ChartColorScheme = Static<typeof ChartColorScheme>;

export const ChartColorMappings = Dictionary(ChartColorHex, String);
export type ChartColorMappings = Static<typeof ChartColorMappings>;

export const BaseChartColor = Record({
  aggregate: Optional(ChartAggregateLiteral),
  scheme: Optional(ChartColorScheme),
  reverse: Optional(Boolean),
  style: Optional(ChartAxisStyle),
  scale: Optional(ChartNumberAxisScale),
  numberFormat: Optional(NumberColumnDisplayFormat),
  datetimeFormat: Optional(DatetimeColumnDisplayFormat),
  dataFrameColumn: Optional(String),
  color: Optional(ChartColorHex),
  dataType: Optional(ChartDataTypeLiteral),
  defaultColor: Optional(ChartColorHex),
  colorsBySeriesValues: Optional(ChartColorMappings),
});
export type BaseChartColor = Static<typeof BaseChartColor>;

export const ChartColorStatic = BaseChartColor.extend({
  type: Literal("static"),
});
export type ChartColorStatic = Static<typeof ChartColorStatic>;

export const ChartColorDataframe = BaseChartColor.extend({
  type: Literal("dataframe"),
  dataType: ChartDataTypeLiteral,
});
export type ChartColorDataframe = Static<typeof ChartColorDataframe>;

export const ChartColorSeriesValue = BaseChartColor.extend({
  type: Literal("series"),
  colorsBySeriesValues: ChartColorMappings,
});
export type ChartColorSeriesValue = Static<typeof ChartColorSeriesValue>;

export const ChartColor = Union(
  ChartColorStatic,
  ChartColorDataframe,
  ChartColorSeriesValue,
);
export type ChartColor = Static<typeof ChartColor>;

export const ChartOpacityStatic = Record({
  type: Literal("static"),
  value: Number,
});
export type ChartOpacityStatic = Static<typeof ChartOpacityStatic>;

export const ChartOpacityDataframe = Record({
  type: Literal("dataframe"),
  dataFrameColumn: Optional(String),
  dataType: ChartDataTypeLiteral,
});
export type ChartOpacityDataframe = Static<typeof ChartOpacityDataframe>;

export const ChartOpacitiesBySeriesValues = Dictionary(Number, String);
export const ChartOpacitySeriesValue = Record({
  type: Literal("series"),
  opacitiesBySeriesValues: ChartOpacitiesBySeriesValues,
});
export type ChartOpacitySeriesValue = Static<typeof ChartOpacitySeriesValue>;

export const ChartOpacity = Union(
  ChartOpacityStatic,
  ChartOpacityDataframe,
  ChartOpacitySeriesValue,
);
export type ChartOpacity = Static<typeof ChartOpacity>;

export const ChartDataLabelPointPosition = Union(
  Literal("auto"),
  Literal("above"),
  Literal("above-left"),
  Literal("above-right"),
  Literal("below"),
  Literal("below-left"),
  Literal("below-right"),
  Literal("left"),
  Literal("right"),
  Literal("center"),
);

export const ChartDataLabelBarPosition = Union(
  Literal("inside-end"),
  Literal("inside-center"),
  Literal("outside-end"),
);

export const ChartDataLabelPosition = Union(
  ChartDataLabelPointPosition,
  ChartDataLabelBarPosition,
);
export type ChartDataLabelPosition = Static<typeof ChartDataLabelPosition>;

export const ChartDataLabels = Record({
  position: Optional(ChartDataLabelPosition),
  angle: Optional(Number),
  color: Optional(ChartColorHex),
  fontSize: Optional(Number),
});
export type ChartDataLabels = Static<typeof ChartDataLabels>;

export const ChartShape = Union(
  Literal("circle"),
  Literal("square"),
  Literal("cross"),
  Literal("diamond"),
  Literal("triangle-up"),
  Literal("triangle-down"),
  Literal("triangle-right"),
  Literal("triangle-left"),
  Literal("tick-vertical"),
  Literal("tick-horizontal"),
  Literal("stroke"),
  Literal("arrow"),
  Literal("wedge"),
  Literal("triangle"),
);
export type ChartShape = Static<typeof ChartShape>;

export const ChartBinType = Union(
  Literal("count"),
  Literal("size"),
  Literal("dataFrameColumn"),
);
export type ChartBinType = Static<typeof ChartBinType>;

export const ChartBin = Record({
  type: ChartBinType,
  value: Optional(Number),
});
export type ChartBin = Static<typeof ChartBin>;

export const ChartHistogramFormat = Union(
  Literal("count"),
  Literal("percentage"),
);
export type ChartHistogramFormat = Static<typeof ChartHistogramFormat>;

export const ChartSeriesBarType = Literal("bar");
export type ChartSeriesBarType = Static<typeof ChartSeriesBarType>;

export const ChartSeriesLineType = Literal("line");
export type ChartSeriesLineType = Static<typeof ChartSeriesLineType>;

export const ChartSeriesScatterType = Literal("scatter");
export type ChartSeriesScatterType = Static<typeof ChartSeriesScatterType>;

export const ChartSeriesAreaType = Literal("area");
export type ChartSeriesAreaType = Static<typeof ChartSeriesAreaType>;

export const ChartSeriesHistogramType = Literal("histogram");
export type ChartSeriesHistogramType = Static<typeof ChartSeriesHistogramType>;

export const ChartSeriesPieType = Literal("pie");
export type ChartSeriesPieType = Static<typeof ChartSeriesPieType>;

export const ChartSeriesTypeLiteral = Union(
  ChartSeriesBarType,
  ChartSeriesLineType,
  ChartSeriesScatterType,
  ChartSeriesAreaType,
  ChartSeriesHistogramType,
  ChartSeriesPieType,
);
export const ChartSeriesType = getNormalEnum(ChartSeriesTypeLiteral);
export type ChartSeriesType = Static<typeof ChartSeriesTypeLiteral>;

export const BaseChartSeries = Record({
  id: String,
  name: Optional(String),
  type: ChartSeriesTypeLiteral,
  axis: ChartAxis,
  dataFrame: Optional(String),
  dataFrameColumns: Array(String),
  colorDataFrameColumn: Optional(String),
  colorOrder: ChartSortOrCustomSort,
  color: ChartColor,
  opacity: ChartOpacity,
  tooltip: Optional(ChartTooltip),
  dataLabels: Optional(ChartDataLabels),
  totalDataLabels: Optional(ChartDataLabels),
  legendTitle: Optional(String),
});
export type BaseChartSeries = Static<typeof BaseChartSeries>;

export const ChartInterpolate = Union(
  Literal("linear"),
  Literal("monotone"),
  Literal("step-before"),
  Literal("step-after"),
  Literal("step"),
  Literal("basis"),
  Literal("cardinal"),
);
export type ChartInterpolate = Static<typeof ChartInterpolate>;

export const ChartLineSeries = BaseChartSeries.extend({
  type: ChartSeriesLineType,
  point: Boolean,
  interpolate: Optional(ChartInterpolate),
  stroke: Optional(ChartLineStroke),
  width: Optional(Number),
});
export type ChartLineSeries = Static<typeof ChartLineSeries>;

export const ChartBarLayout = Union(
  Literal("grouped"),
  Literal("stacked"),
  Literal("stacked100"),
);
export type ChartBarLayout = Static<typeof ChartBarLayout>;

export const ChartBarSeries = BaseChartSeries.extend({
  type: ChartSeriesBarType,
  orientation: Union(Literal("horizontal"), Literal("vertical")),
  barWidth: Number,
  layout: ChartBarLayout,
});
export type ChartBarSeries = Static<typeof ChartBarSeries>;

export const ChartScatterSeries = BaseChartSeries.extend({
  type: ChartSeriesScatterType,
  filled: Boolean,
  shape: Optional(ChartShape),
  size: Optional(Number),
});
export type ChartScatterSeries = Static<typeof ChartScatterSeries>;

export const ChartAreaSeries = BaseChartSeries.extend({
  type: ChartSeriesAreaType,
  line: Boolean,
  point: Boolean,
  interpolate: Optional(ChartInterpolate),
  normalize: Boolean,
});
export type ChartAreaSeries = Static<typeof ChartAreaSeries>;

export const ChartHistogramSeries = BaseChartSeries.extend({
  type: ChartSeriesHistogramType,
  bin: ChartBin,
  format: ChartHistogramFormat,
});
export type ChartHistogramSeries = Static<typeof ChartHistogramSeries>;

export const ChartPieSeries = BaseChartSeries.extend({
  type: ChartSeriesPieType,
  radius: Number,
  showAsPct: Optional(Boolean),
});
export type ChartPieSeries = Static<typeof ChartPieSeries>;

export const ChartSeries = Union(
  ChartLineSeries,
  ChartBarSeries,
  ChartScatterSeries,
  ChartAreaSeries,
  ChartHistogramSeries,
  ChartPieSeries,
);
export type ChartSeries = Static<typeof ChartSeries>;

export const ChartSeriesGroup = Array(String);
export type ChartSeriesGroup = Static<typeof ChartSeriesGroup>;

export const ChartFacetDimension = Record({
  type: ChartDataTypeLiteral,
  dataFrameColumn: Optional(String),
  sort: Optional(ChartSortOrCustomSort),
  maxBins: Optional(Number),
  binStep: Optional(Number), //note: there's no UI for this yet?
  timeUnit: Optional(ChartTimeUnit),
});
export type ChartFacetDimension = Static<typeof ChartFacetDimension>;

export const ChartFacet = Record({
  columns: Optional(Number),
  sharedY: Optional(Boolean),
  facetHorizontal: Optional(ChartFacetDimension),
  facetVertical: Optional(ChartFacetDimension),
});
export type ChartFacet = Static<typeof ChartFacet>;

export const ChartLayer = Record({
  id: String,
  dataFrame: Optional(NoCodeCellDataframe),
  xAxis: ChartAxisWithData,
  series: Array(ChartSeries),
  seriesGroups: Optional(Array(ChartSeriesGroup)),
});
export type ChartLayer = Static<typeof ChartLayer>;

export const ChartLegendOrientLiteral = Union(
  Literal("none"),
  Literal("left"),
  Literal("right"),
  Literal("top"),
  Literal("bottom"),
  Literal("top-left"),
  Literal("top-right"),
  Literal("bottom-left"),
  Literal("bottom-right"),
);
export const ChartLegendOrient = getNormalEnum(ChartLegendOrientLiteral);
export type ChartLegendOrient = Static<typeof ChartLegendOrientLiteral>;

export const ChartFont = Record({
  baseSize: Number,
});
export type ChartFont = Static<typeof ChartFont>;

export const ChartLegend = Record({
  position: ChartLegendOrientLiteral,
});
export type ChartLegend = Static<typeof ChartLegend>;

export const ChartType = Union(Literal("layered"), Literal("concat"));
export type ChartType = Static<typeof ChartType>;

export const ConcatChart = Record({
  id: String,
  layers: Array(ChartLayer),
});
export type ConcatChart = Static<typeof ConcatChart>;

export const ChartGeneralSettings = Record({
  legend: ChartLegend,
  font: Optional(ChartFont),
  tooltip: Boolean,
  timezone: Optional(TimezoneName),
  selectionEnabled: Optional(Boolean), // Optional for backward compatibility
});
export type ChartGeneralSettings = Static<typeof ChartGeneralSettings>;

const BaseChartSpec = Record({
  type: ChartType,
  settings: ChartGeneralSettings,
});

export const LayeredChartSpec = BaseChartSpec.extend({
  type: Literal("layered"),
  layers: Array(ChartLayer),
  facet: Optional(ChartFacet),
});
export type LayeredChartSpec = Static<typeof LayeredChartSpec>;

export const ConcatChartSpec = BaseChartSpec.extend({
  type: Literal("concat"),
  columns: Number,
  charts: Array(ConcatChart),
});
export type ConcatChartSpec = Static<typeof ConcatChartSpec>;
export const UnsupportedChartSpec = Record({
  type: Literal("unsupported"),
});
export type UnsupportedChartSpec = Static<typeof UnsupportedChartSpec>;

export const ChartSpec = Union(
  LayeredChartSpec,
  ConcatChartSpec,
  UnsupportedChartSpec,
);
export type ChartSpec = Static<typeof ChartSpec>;

export const VegaIntervalNumericRange = Tuple(Number, Number);
export type VegaIntervalNumericRange = Static<typeof VegaIntervalNumericRange>;

export const VegaIntervalDatetimeRange = Tuple(String, String);
export type VegaIntervalDatetimeRange = Static<
  typeof VegaIntervalDatetimeRange
>;

export const VegaIntervalRange = Union(
  VegaIntervalNumericRange,
  VegaIntervalDatetimeRange,
);
export type VegaIntervalRange = Static<typeof VegaIntervalRange>;

export const VegaIntervalStringCategories = Array(String);
export type VegaIntervalStringCategories = Static<
  typeof VegaIntervalStringCategories
>;

export const VegaIntervalNumericCategories = Array(Number);
export type VegaIntervalNumericCategories = Static<
  typeof VegaIntervalNumericCategories
>;

export const VegaIntervalBooleanCategories = Array(Boolean);
export type VegaIntervalBooleanCategories = Static<
  typeof VegaIntervalBooleanCategories
>;

export const VegaIntervalCategories = Union(
  VegaIntervalStringCategories,
  VegaIntervalNumericCategories,
  VegaIntervalBooleanCategories,
);
export type VegaIntervalCategories = Static<typeof VegaIntervalCategories>;

export const VegaIntervalSelection = Dictionary(
  Union(VegaIntervalRange, VegaIntervalCategories),
  String,
);
export type VegaIntervalSelection = Static<typeof VegaIntervalSelection>;

export const VegaPointSelectionScalar = Union(String, Number, Boolean);
export type VegaPointSelectionScalar = Static<typeof VegaPointSelectionScalar>;

export const VegaPointSelectionDatum = Dictionary(
  VegaPointSelectionScalar,
  String,
);
export type VegaPointSelectionDatum = Static<typeof VegaPointSelectionDatum>;

export const VegaPointSelection = Array(VegaPointSelectionDatum);
export type VegaPointSelection = Static<typeof VegaPointSelection>;

export const VegaSelection = Union(VegaIntervalSelection, VegaPointSelection);
export type VegaSelection = Static<typeof VegaSelection>;

export const ChartSelection = Dictionary(VegaSelection, String);
export type ChartSelection = Static<typeof ChartSelection>;

export const VegaSelectionType = Union(Literal("point"), Literal("interval"));
export type VegaSelectionType = Static<typeof VegaSelectionType>;

export const DerivedFieldType = Union(
  Literal("rename"),
  Literal("bin"),
  Literal("timeunit"),
);
export type DerivedFieldType = Static<typeof DerivedFieldType>;

export const BaseDerivedField = Record({
  type: DerivedFieldType,
  derivedName: String,
  sourceName: String,
});
export type BaseDerivedField = Static<typeof BaseDerivedField>;

export const RenamedField = BaseDerivedField.extend({
  type: Literal("rename"),
});
export type RenamedField = Static<typeof RenamedField>;

export const BinsSignal = Record({
  name: String,
  scope: Array(Number),
});
export type BinsSignal = Static<typeof BinsSignal>;

export const BinsConfig = Record({
  start: Number,
  step: Number,
  stop: Number,
});
export type BinsConfig = Static<typeof BinsConfig>;

export const NumericBinnedField = BaseDerivedField.extend({
  type: Literal("bin"),
  binsSignal: Optional(BinsSignal),
  binsConfig: Optional(BinsConfig),
});
export type NumericBinnedField = Static<typeof NumericBinnedField>;

export const TimeunitBinnedField = BaseDerivedField.extend({
  type: Literal("timeunit"),
  timeUnit: ChartTimeUnitLiteral,
});
export type TimeunitBinnedField = Static<typeof TimeunitBinnedField>;

export const SelectionDerived = Array(
  Union(RenamedField, NumericBinnedField, TimeunitBinnedField),
);
export type SelectionDerived = Static<typeof SelectionDerived>;

export const VegaSelectionConfig = Record({
  type: VegaSelectionType,
  datetimeFields: Array(String),
  derived: SelectionDerived,
});
export type VegaSelectionConfig = Static<typeof VegaSelectionConfig>;

// For each Vega-Lite selection that the chart translator adds to the resulting
// Vega-Lite spec, a corresponding VegaSelectionConfig is created.
// The VegaSelectionConfigs are stored in the Vega-Lite spec's usermeta object,
// which gets passed through the Vega-Lite to Vega compilation process and through
// the VegaFusion pre-transform process. The VegaSelectionConfigs are used
// by our Vega renderer component to determine the callbacks that are needed,
// and to determine any data conversions that should be performed in the callbacks
// (in particular, which datetime fields should be converted into strings)
export const VegaSelectionConfigs = Dictionary(VegaSelectionConfig, String);
export type VegaSelectionConfigs = Static<typeof VegaSelectionConfigs>;

export const ColumnKind = Union(
  Literal("bool"),
  Literal("datetime"),
  Literal("float"),
  Literal("int"),
  Literal("string"),
);
export type ColumnKind = Static<typeof ColumnKind>;

export const ChartSqlColumnMappings = Dictionary(String, String); // new name -> old name
export type ChartSqlColumnMappings = Static<typeof ChartSqlColumnMappings>;
